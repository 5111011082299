import { render, staticRenderFns } from "./OurTeam.vue?vue&type=template&id=5805ee6b&scoped=true&"
var script = {}
import style0 from "./OurTeam.vue?vue&type=style&index=0&id=5805ee6b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5805ee6b",
  null
  
)

export default component.exports